import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import "rc-tooltip/assets/bootstrap.css"
import Lottie from "react-lottie"
import animationDataGif from "../../static/img/gifIris.json"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import {
    ServiceSection,
    LearnMoreButton,
    GetStartedButtonWhite,
    CountsSection,
    AttributeSection,
    ContactSection,
    ProductSummaryWidget,
    AutoScrollBanner,
    TryIrisGPTForFreeBanner,
    FeatureSection
} from "../commonComponents"


export default function Home() {

  return (
    <Layout
        classes={{
            main: "section-spacing"
        }}
        title="IrisAgent Case Studies and Customer Success Stories"
      keywords="Case Studies, IrisAgent customers"
      description="See how IrisAgent's AI-powered agent assist automates 40% of customer support tickets and boosts agent productivity. Discover success stories and real results!"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/customers/" />
      </Helmet>

      <section
        id="hero-new-design"

      >
        <div className="container w-100 mw-100 m-0">
            <div className={"row"}>
                <div
                    className={"col-lg-12 col-md-12 col-sm-24 col-xs-24 section-padding-left section-padding-right"}
                    style={{ paddingLeft: "0" }}
                >
                <br/>
                    <h1 className="title-support-new-design" style={{fontWeight: 500, textAlign: "center"}}>
                        Happy Customers, Powered by <span className="text-highlight">AI</span>
                    </h1>
                    <div className="description-support-new-design w-100" style={{textAlign: "center"}}>
                        Unlock New Levels of Efficiency and Customer Delight
                    </div>


                <div style={{ paddingTop: "3%", paddingBottom: "3%" ,textAlign:"center", display:"flex", gap:"20px", justifyContent:"center"}}>
                   <GetStartedButtonWhite label="BOOK A DEMO" to="/get-a-demo/"
                   />
                   <LearnMoreButton
                    label="TRY FOR FREE"
                    to="/try-for-free/"
                   />
                 </div>
                 <br/><br/>

                </div>
            </div>
        </div>
      </section>
      {/* <!-- Hero Section --> */}

      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

      <br/><br/>
      {/* <!-- ======= Chatbot Section ======= --> */}

          <div className="container" style={{backgroundColor: "#f9f9f9", paddingTop: "2%", paddingBottom:"2%"}}>
          <FeatureSection
            title="Zuora solves customer issues 10x faster with IrisAgent AI"
            description="Zuora VP of Support: IrisAgent is critical to our strategy of AI-powered customer support. IrisAgent has successfully deployed intelligent ticket routing, AI answers, and customer self-serve to improve our support KPIs and efficiency."
            imageUrl="/img/zuora-story.png"
            buttonLabel="READ THE STORY"
            buttonURL="/zuora/"
            imageWidth= "80%"
            padding = "2%"
          />
          </div><br/><br/>


          <div className="container" style={{backgroundColor: "#f9f9f9", paddingTop: "2%", paddingBottom:"2%"}}>
          <FeatureSection
            title="Teachmint solves 35% customer issues with IrisAgent AI"
            description="Teachmint Director of Operations: IrisAgent has hit bull's eye by infusing the power of AI in the customer support function to take it to a whole new spectrum at scale. Automatic tagging of tickets, sentiment analysis, IrisGPT, etc., are some of its propriety tools that can unlock the new potential of the support functions and add value to the customers and parallelly improving its operational efficiencies by heaps and bounds."
            imageUrl="/img/teachmint-story.png"
            buttonLabel="READ THE STORY"
            buttonURL="/teachmint-case-study/"
            imageWidth= "80%"
            padding = "2%"
          />
          </div><br/><br/>

          <div className="container" style={{backgroundColor: "#f9f9f9", paddingTop: "2%", paddingBottom:"2%"}}>
          <FeatureSection
            title="Logz.io auto-tags tickets with IrisAgent AI"
            description="Logz.io Director of Support: Our support agents have been happy with IrisAgent’s impact on their daily work. They’ve been especially impressed with the platform’s ability to automate tagging —an area our support team was especially eager to improve—and I value the added insight into our customer’s overall health IrisAgent provides in assisting agents."
            imageUrl="/img/logz-story.png"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "80%"
            padding = "2%"
          />
          </div><br/><br/>

          <div className="container" style={{backgroundColor: "#f9f9f9", paddingTop: "2%", paddingBottom:"2%"}}>
          <FeatureSection
            title="Agorapulse saves 30% of support time with IrisAgent AI"
            description="Agorapulse Support Manager: We struggled in the past with tagging. Some of the issues we faced were based on human error. For us tagging is important for product insight, but also for identifying the topics we spent most time on and trying to either apply some proactive support/deflection measure for these topics. In the end, we started using IrisAgent to automatically tag our conversations. We’ve found this really beneficial and our product team loves it too."
            imageUrl="/img/agorapulse-story.png"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "80%"
            padding = "2%"
          />
          </div>



      {/* <!-- ======= Services Section ======= --> */}
      <AttributeSection></AttributeSection>
      {/* <!-- End Services Section --> */}
            {/*  ======= Cta Section =======  */}
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}

      {/* <!-- ======= Services Section ======= --> */}
      <ProductSummaryWidget />
      {/* <!-- End Services Section --> */}
      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



    </Layout>
  )
}
